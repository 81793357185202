


//                                                    
//    ## ##  #####  ##     ####   #####  ####    ###  
//    ## ##  ##     ##     ## ##  ##     ## ##  ## ## 
//    ## ##  ##     ##     ## ##  ##     ## ##  ##    
//    #####  ####   ##     ####   ####   ####    ###  
//    ## ##  ##     ##     ##     ##     ###       ## 
//    ## ##  ##     ##     ##     ##     ####   ## ## 
//    ## ##  #####  #####  ##     #####  ## ##   ###  
//                                                    
//

.clearfix {
  @include clearfix;
}

.clear {
    clear:both;
    display:block;
    overflow:hidden;
    visibility:hidden;
    width:0;
    height:0
}


//                                                                           
//       ###    ###   #  ## ######    #    ####   #  ##  #####  ####    ###  
//      ## ##  ## ##  ## ##   ##     ###    ##    ## ##  ##     ## ##  ## ## 
//      ##     ## ##  #####   ##    ## ##   ##    #####  ##     ## ##  ##    
//      ##     ## ##  #####   ##    ## ##   ##    #####  ####   ####    ###  
//      ##     ## ##  #####   ##    #####   ##    #####  ##     ###       ## 
//      ## ##  ## ##  ## ##   ##    ## ##   ##    ## ##  ##     ####   ## ## 
//       ###    ###   ##  #   ##    ## ##  ####   ##  #  #####  ## ##   ###  
//                                                                           
//                                                                           
.full-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: initial;
}

.full-height {
    height: 100%;
}


.full-width-1400 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1400px;
}

.full-width-1600 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
}

.width-90-percent {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    @include breakpoint(medium){
        width: 92%;
    }
}




.box{
    padding:30px 30px;
     @include breakpoint(medium){
              padding:20px 20px;
        }
}







.position-relative{
    position: relative;
}

.position-absolute{
    position: absolute;
}



.sticky{
    position:fixed;
}

.noselect {
  user-select: none;          
}








//                                                      
//      ####   ## ## ###### ######   ###   #  ##   ###  
//      ## ##  ## ##   ##     ##    ## ##  ## ##  ## ## 
//      ## ##  ## ##   ##     ##    ## ##  #####  ##    
//      ####   ## ##   ##     ##    ## ##  #####   ###  
//      ## ##  ## ##   ##     ##    ## ##  #####     ## 
//      ## ##  ## ##   ##     ##    ## ##  ## ##  ## ## 
//      ####    ###    ##     ##     ###   ##  #   ###  
//                                                      
//                                                      

.button{
        font-weight: 700;
        letter-spacing: 0.5px;
        padding:15px 25px 18px 25px;
        border-radius:3px;
        border: 2px solid $black;
       
        font-family: $font-2;
        transition: all 100ms ease-in;
        &:hover{
          background:$black;
            color:$white;
        }
}






//                                                                                             
//   #   #  #####  ####   ####     #            ###   ####     ###  #####   ###  ######        
//   ## ##  ##     ## ##   ##     ###          ## ##  ## ##     ##  ##     ## ##   ##          
//   #####  ##     ## ##   ##    ## ##         ## ##  ## ##     ##  ##     ##      ##          
//   #####  ####   ## ##   ##    ## ##         ## ##  ####      ##  ####   ##      ##          
//   #####  ##     ## ##   ##    #####         ## ##  ## ##  ## ##  ##     ##      ##          
//   ## ##  ##     ## ##   ##    ## ##         ## ##  ## ##  ## ##  ##     ## ##   ##          
//   ## ##  #####  ####   ####   ## ##          ###   ####    ###   #####   ###    ##          
//                                                                                             
//  
/* URL: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/

<div class="media">

  <a href="http://twitter.com/stubbornella" class="media__image">
    <img src="http://stubbornella.com/profile_image.jpg" alt="me" />
  </a>

  <div class="media__body">
    @Stubbornella 14 minutes ago
  </div>

</div>

*/
.media {

    overflow: hidden;
}

.media, .media__body {
  overflow:hidden;
  _overflow:visible;
  zoom:1;
}

.media__image {
    float: left;

}

.media .media__image img {
    display: block;
}

// FLIPPED
.media--flip > .media__image {
    margin-right: 0;

    // resetting the default right margin
    margin-left: 10px;
    float: right;
}

// STACKED
.media--stack > .media__image {
    float: none;
}

// NOWRAP
.media--nowrap > .media__body {
    overflow: hidden;

    // this property doesn't allow text to wrap around the floated image.
}

// VALIGN (aka "FLAG")
.media--valign {
    display: table;
}

.media--valign > .media__image,
.media--valign > .media__body {
    display: table-cell;
    vertical-align: middle;
}

.media--valign > .media__image {
    float: none;
}

.media--valign > .media__body {
    width: 100%;
}
