.svg--white{
	fill:$white;
}

.parallax-photo{
	height:60vh;
	overflow: hidden;
	position: relative;
	background: $secondary-color;
	max-height: 400px;
	@include breakpoint(medium){
		height:80vh;
		max-height: 600px;
	}
	img{
		width:100vw;
		
		height:125%;
		object-fit: cover;
		background:black;
	}
	&__text{
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		width:80%;
		text-align: center;
		color:white;
	}
	&--narrow{
		height:30vh;
		@include breakpoint(medium){
		height:50vh;
		max-height: 400px;
		}
	}
	&--opacity{
		img{
			opacity: 0.8;
		}
	}
	&--bottom{
		img{
		    object-position: bottom;
		}
	}
}
