@charset 'UTF-8';

//Ręcznie dodane
// @import 'bower_components/chocolat/src/css/chocolat';


// bower:scss
@import 'bower_components/foundation-sites/scss/foundation.scss';
// endbower




  // foundation
  @include foundation-global-styles;
  @include foundation-flex-grid;
  @include foundation-grid;
  @include foundation-xy-grid-classes;
  @include foundation-typography;
  @include foundation-visibility-classes;
  @include foundation-float-classes;
  @include foundation-flex-classes;
  // @include foundation-prototype-classes;
  // $prototype-transformation-breakpoints: true;
  
  @import "foundation_global";

//OCSS


//mixins
 
@import "modules/rfs";

//moje
@import "colors";
@import "typography"; 
@import "spacing";
@import "sizes";
@import "base"; 
 
@import "other"; 

@import "layouts/main-layout";
@import "layouts/strona-glowna";
@import "layouts/oferta";

.content{
  @import "modules/content-styles";
}

@import "modules/integrator-cta";
@import "modules/top-nav";
@import "modules/left-nav";
@import "modules/slider";
@import "modules/footer";
@import "modules/golf-baner";



@import "galeria";

@import "playground";