.oferta{

  &__tabela{
    font-size:0.8rem;
    tr{
      vertical-align: top;
    }
    td{
      padding-bottom:0.5rem;
    }
    td:first-child{
      padding-right:1rem;
     
    }
  }
  
}