// 	
// 	 ####   #  ## ######  #####   ###   ####     #   ######   ###   ####
// 	  ##    ## ##   ##    ##     ## ##  ## ##   ###    ##    ## ##  ## ##
// 	  ##    #####   ##    ##     ##     ## ##  ## ##   ##    ## ##  ## ##
// 	  ##    #####   ##    ####   #####  ####   ## ##   ##    ## ##  ####
// 	  ##    #####   ##    ##     ## ##  ###    #####   ##    ## ##  ###
// 	  ##    ## ##   ##    ##     ## ##  ####   ## ##   ##    ## ##  ####
// 	 ####   ##  #   ##    #####   ####  ## ##  ## ##   ##     ###   ## ##
// 	
// 		                              ###

 .integrator-cta{
        padding:1rem 1rem;
        background:#fff;
        display: flex;
        // align-items: center;
        width: 100%;
        *{
            cursor: pointer;
        }
        &__body{
             display: flex;
             flex-direction: column;
        }
        h3{
            margin-top:0.4rem;
            margin-bottom:0.2rem;
            font-size:1rem;
            font-weight: 400;
            width: 100%;
        }
        p{
            font-size: 0.75rem;
            font-weight: 300;
            width: 100%;
            margin-bottom:1rem;
            // flex:1;
            align-self: stretch;
        }
        a{
           font-size: 0.8rem;
            font-weight: 300;
           
           float:left;
          
           // align-self: flex-end;
        }
        figure{
            text-align: center;
             margin-right:1.5rem;

            img{
                width: 6.2rem;
                height: 6.2rem;
                object-fit:cover;
                max-width:initial;
                margin-bottom:0.2rem;
                transition:all 200ms ease;
                    &:hover{
                        opacity:0.9;
                    }
                }
            strong{
                font-weight: 400;
            }
            figcaption,span{
                font-size: 0.6rem;
                color:$black;
            }
            span{
                color:$gray-darker;
                font-size: 0.55rem;
            }
            
        }
    }
.integrator-cta--no-padding{
    padding: 0 0;
}
.integrator-cta--horizontal{
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
        figure{
            display: flex;
            text-align: left;
            align-items: flex-end;
            a{ display: flex;
            text-align: left;
            align-items: flex-end;}
            figcaption{
                background:#fff;
                padding:0.4rem 0.4rem 0.2rem 0.4rem;
                line-height:1.5em;
                margin-left:-1rem;
                margin-bottom:0.6rem;
                z-index:10;
            }
        }
}
.integrator-cta--gray{
    background:$gray-lighter;
}
.integrator-cta--footer{
    max-width:24rem;
    order:-2;
    margin-bottom:2rem;
    margin-right:100%;
    flex-grow:1;
    flex-shrink:0;
    @include breakpoint(medium){
        order:initial;
        margin-bottom:initial;
         margin-right:initial;
        }
    @include breakpoint(750px){
             width: 24rem;
              margin-left:auto;          
        }
        @include breakpoint(large){
             width: 24rem;
        }
}
.integrator-cta--realizacja{
    padding:0 0;
    flex-direction: column;
    margin-top:2rem;  

        @include breakpoint(750px){
            margin-top:0;  
              margin-left:auto;
        }
        @include breakpoint(large){
             
        }
        h3{
            font-family: $font-1;
            font-size: 0.7rem;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            font-size: 0.5rem;
            margin-bottom:0.5rem;
            color:$gray-darker;
        }
        &__image{
            width:5rem;
            height:5rem;
            margin-right:5%;
            display: block;
             @include breakpoint(medium){
                  width:30%;
                  height:30%;  
                }
            img{
                width: 100% !important;
                height: 100% !important;
            }
        }
        .link--strzalka{
            font-size: 0.7rem;
           margin-top:0.2rem;
            margin-bottom:0.1rem;
        }
        figure {
            margin: 0 0;
            display: flex !important;
           
            align-items: flex-end;
    
            figcaption{
                
                width:60%;
                display: flex;
                flex-direction: column;

            }
            
            strong{
                font-weight: 400;
                color:$black;

            }
            figcaption,span{
                font-size: 0.7rem;
                color:$black;
            }
            span{
                color:$black;
                font-size: 0.7rem;
            }
            
        }
}