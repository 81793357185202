/* Margin and Padding helpers inspired by boostrap but made also for rem values
* {margin/padding}{side}-{breakpoint}-{value}
* ex.: .mr-1, .ml-1, pt-1, mb-1, my-1, px-1
* for reset: .mr-0, my-0 
* for auto: .mr-auto, my-auto
* for breakpoints: .mr-small-1, my-large-1
*/

// jednostka np rem lub px
$space-unit: rem;

// wielkość jednego odstępu
$space-value: 0.2;

//class values
$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20); // Adjust this to include the pixel amounts you need.



// Margin and Padding

@each $breakpoint in map-keys($breakpoints) {
  @include breakpoint($breakpoint) {
	$multiply: 2 !default;
  	@if $breakpoint == "small"  {
  		// przelicznik small
       $multiply: 1;
   	}
   	@else if $breakpoint == "medium"  {
   		// przelicznik medium
       $multiply: 1.5;
   	}
   	@else if $breakpoint == "large"  {
   		// przelicznik large
       $multiply: 2;
   	}
   	@else if $breakpoint == "xlarge"  {
   		// przelicznik xlarge
       $multiply: 3;
   	}
   	@else if $breakpoint == "xxlarge"  {
   		// przelicznik xxlarge
       $multiply: 4;
   	}
   
   

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size in $spaceamounts{
      	.#{$abbrev}-#{$size} { #{$prop}: #{$size*$space-value*$multiply}#{$space-unit} !important; }
        .#{$abbrev}y-#{$size},
        .#{$abbrev}t-#{$size}{
          #{$prop}-top: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
        .#{$abbrev}x-#{$size},
        .#{$abbrev}r-#{$size}{
          #{$prop}-right: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
        .#{$abbrev}y-#{$size},
        .#{$abbrev}b-#{$size}{
          #{$prop}-bottom: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }    
        .#{$abbrev}x-#{$size},
        .#{$abbrev}l-#{$size}{
          #{$prop}-left: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
      }
    }

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size in $spaceamounts{
        .#{$abbrev}-#{$breakpoint}-#{$size} { #{$prop}: #{$size*$space-value*$multiply}#{$space-unit} !important; }
        .#{$abbrev}y-#{$breakpoint}-#{$size},
        .#{$abbrev}t-#{$breakpoint}-#{$size} {
          #{$prop}-top: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
        .#{$abbrev}x-#{$breakpoint}-#{$size},
         .#{$abbrev}r-#{$breakpoint}-#{$size}{
          #{$prop}-right: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
        .#{$abbrev}y-#{$breakpoint}-#{$size}, 
        .#{$abbrev}b-#{$breakpoint}-#{$size}{
          #{$prop}-bottom: #{$size*$space-value*$multiply}#{$space-unit} !important;
        } 
        .#{$abbrev}x-#{$breakpoint}-#{$size},   
        .#{$abbrev}l-#{$breakpoint}-#{$size}
          {
          #{$prop}-left: #{$size*$space-value*$multiply}#{$space-unit} !important;
        }
      }
    }



    // Some special margin utils
    .m-auto { margin: auto !important; }
    .mt-auto,
    .my-auto {
      margin-top: auto !important;
    }
    .mr-auto,
    .mx-auto {
      margin-right: auto !important;
    }
    .mb-auto,
    .my-auto {
      margin-bottom: auto !important;
    }
    .ml-auto,
    .mx-auto {
      margin-left: auto !important;
    }

    .m-#{$breakpoint}-auto { margin: auto !important; }
    .mt-#{$breakpoint}-auto,
    .my-#{$breakpoint}-auto {
      margin-top: auto !important;
    }
    .mr-#{$breakpoint}-auto,
    .mx-#{$breakpoint}-auto {
      margin-right: auto !important;
    }
    .mb-#{$breakpoint}-auto,
    .my-#{$breakpoint}-auto {
      margin-bottom: auto !important;
    }
    .ml-#{$breakpoint}-auto,
    .mx-#{$breakpoint}-auto {
      margin-left: auto !important;
    }
  }
}





