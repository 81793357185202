
.golf-baner{
	background: $white;

    padding:15px 15px 10px 15px;
   
    z-index: 11;
    position: relative;
    display: inline-block;
    margin: 30px auto;

    img{
        display: block;
        width:187px;
        margin: 0 auto;
    }
 
}