/* Sizes
* w-10 = width:10%
* h-50 = height:50%;
*
*/

$sizes: () !default;
$sizes: map-merge((
  10: 10%,
  15: 15%,
  20: 20%,
  25: 25%,
  30: 30%,
  35: 35%,
  40: 40%,
  45: 45%,
  50: 50%,
  55: 55%,
  60: 60%,
  65: 65%,
  70: 70%,
  75: 75%,
  80: 80%,
  85: 85%,
  90: 90%, 
  95: 95%,
  100: 100%
), $sizes);


@each $breakpoint in map-keys($breakpoints) {
  @include breakpoint($breakpoint) {
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $perc in $sizes {
      	.#{$abbrev}-#{$size} { 
            #{$prop}:#{$perc} !important; 
          }
        .#{$abbrev}-#{$breakpoint}-#{$size} { 
            #{$prop}:#{$perc} !important; 
        }
      }
    }
  }
}

.h-30vh{
  height: 30vh;
}
.h-50vh{
  height: 50vh;
}
.h-60vh{
  height: 60vh;
}
.h-80vh{
  height: 80vh;
}
.h-100vh{
  height: 100vh;
}
.w-30vw{
  width:30vw;
}
.w-50vw{
  width:50vw;
}
.w-60vw{
  width:60vw;
}
.w-100vw{
  width:100vw;
}



.cover{
    object-fit: cover;
    height: 100%;
    width: 100%;
}




