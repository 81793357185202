
// .media, .media__body {
//   overflow:hidden;
//   _overflow:visible;
//   zoom:1;
// }

body.strona-glowna{
  background:$black-dark;
}



.video-hero{
    @include xy-grid-container;
    @include flex-align(center);
    &__body{
        @include xy-grid;
        height: 100vh;
    }   
    &__logo{
        @include xy-cell(auto);
    }
    &__header{
        @include xy-cell(auto);
    }
    &__link{
        @include xy-cell(auto);
        @include flex-align-self(bottom);
    }
}





//
//      ## ##  #####  ####    ###
//      ## ##  ##     ## ##  ## ##
//      ## ##  ##     ## ##  ## ##
//      #####  ####   ####   ## ##
//      ## ##  ##     ###    ## ##
//      ## ##  ##     ####   ## ##
//      ## ##  #####  ## ##   ###
//
//

.hero-video{
    position:relative;
    // height: calc(100vh - 50px); 
    height:100vh;
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: hidden;

    flex-wrap: wrap;
    background: #000;
    margin-top:-60px;  
    @include breakpoint(medium){
        max-height:initial;
        margin-top:-126px;  
    }
    @include breakpoint(large){
     height: calc(100vh); 
       min-height:400px;
       max-height:1000px;
   
     
    }

    &__movie{
        
      position: absolute;
      top:50%;
      bottom:50%;
      left: 50%;
      right: 50%;
      transform: translate(-50%,-50%);
       z-index: 1;
       min-height: 100vh;
        min-width: 100vw;
   }
       &__image{
        object-fit: cover;
        opacity: 0.85;
         position: absolute;
         width:100%;
         width: 100%;
         height: 100%; 
         top:0;
         left:0;
    
   }

      &__logo{
       position:relative;
        height: 7vw;
        max-width:150px;
        margin-bottom:3vh;
        z-index: 2;
        @include breakpoint(medium){
             height: 5vw;
             max-width:200px;
        }
         @include breakpoint(large){
             height: 3vw;
        }

   }

  &__centrum{
    text-align:center;
   
    flex-basis: 100%;

    
  }
   &__napis{
    position:relative;
    margin:0 auto;
    font-family: $font-2;
    color: $white;
    font-size: 2rem;
    z-index: 3;
    width:auto;
    text-align: center;
    margin: 0 2rem;
    line-height:1.5em;
    @include breakpoint(medium){
      font-size:2.3rem;
      
    }
   }

   &__czym{
    margin-top:2rem;
    width: 100%;
    text-align: center;
    height: 60px;
    color: $white;
    font-size: 0.8rem;
    letter-spacing: 1px;
    font-weight: 500;
    bottom:3%;
    font-family:$font-2;
    left: 0;
    right: 0;
    z-index: 2;
     cursor:pointer;
     transition: all .2s ease;
      position:absolute;
     @include breakpoint(large){
     bottom:4%;
     }
     &:hover{
      transform: translateY(7%);
     }
    a{
        color: $white;
        display: block; 
    }

    svg{
        width:5vw;
        @include breakpoint(medium){
           width:3vw; 
        }
      & path{
        
      }
        color: $white;
        
        height: 15px;
        display: block;
        margin:0 auto;
        position: relative;
        animation: fontbulger 1.3s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    img{
      height: 15px;
    }
    @keyframes fontbulger {
        0%, 100% {
            top:10px;
        }
        50% {
            top:25px;
        }
    }

    }
}










//
//       ###   #####  ## ##  #   #           ###  #####   ###  ######
//      ## ##     ##  ## ##  ## ##            ##  ##     ## ##   ##
//      ##       ###  ## ##  #####            ##  ##     ##      ##
//      ##      ###    ###   #####            ##  ####    ###    ##
//      ##     ###     ###   #####         ## ##  ##        ##   ##
//      ## ##  ##      ###   ## ##         ## ##  ##     ## ##   ##
//       ###   #####   ###   ## ##          ###   #####   ###    ##
//
//



.czym-jest{
    position:relative;
    min-height:100vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
   
    // padding-top: $module*5;
    &__logo{
        height: 7vw;
        margin-bottom: 2rem;
        @include breakpoint(medium){
          height: 4vw;
          max-width:80px;
        }
    }

    &__ikony{
        margin-bottom: 10vh;
        height: 40vh;
        @include breakpoint(large){
             height: initial;
              
        }

    }
    &__header{
        font-family: $font-1;
        color: $white;
        max-width:800px;
        font-size: 17px;
        margin: 0 auto;
        padding-left:1rem;
        padding-right:1rem;
        @include breakpoint(medium){
             font-size: 24px;
        }
    }

   &__czym{
   cursor:pointer;

   
    @include breakpoint(large){
       // position:absolute;
    }
    
    p{
        margin: 0 auto;

    }
    &:hover{
        color: $white;
  
      
    }
    &:active{
        color: $white;
    }
    
   }


   &__strzalka{
   position: absolute;

  bottom:4%;
  left: 50%;
  right: 50%;
  transform: translate(-50%,0);
    height: 15px;
      z-index: 2;  
   animation: fontbulgerr 1.5s infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
   }

  @keyframes fontbulgerr {
  0%, 100% {
    bottom:2%;
  }
  50% {
    bottom:6%;
  }
}




}




//
//       ###   #####   ###   ## ##    #           ## ##  #####    #    ####   #####  ####
//      ## ##  ##     ## ##  ## ##   ###          ## ##  ##      ###   ## ##  ##     ## ##
//      ##     ##     ##     ## ##  ## ##         ## ##  ##     ## ##  ## ##  ##     ## ##
//      ##     ####   ##     #####  ## ##         #####  ####   ## ##  ## ##  ####   ####
//      ##     ##     ##     ## ##  #####         ## ##  ##     #####  ## ##  ##     ###
//      ## ##  ##     ## ##  ## ##  ## ##         ## ##  ##     ## ##  ## ##  ##     ####
//       ###   #####   ###   ## ##  ## ##         ## ##  #####  ## ##  ####   #####  ## ##
//
//


.cecha{
   
  
    padding-top:5rem;
    padding-bottom:5rem;
    position:relative;
    display: flex;
    // justify-content: center;
    flex-direction: column;
    @include breakpoint(medium){
      padding-top:3rem;
      padding-bottom:3rem;  
    }
    &--hidden{
      overflow:hidden;
    } 
    &__logo{
        height:2rem;
        width:8rem;
        margin-bottom:0;
        
        padding:0.1rem 0.1rem;
        @include breakpoint(medium){
           
           height:1.7rem;
            width:7rem; 
        }
        
    }
    &__header{
        font-family: "bebas-neue", "nimbus-sans-condensed",sans-serif;
        color: $white;
        letter-spacing: 0.15em;
        font-size: 2rem;
        line-height:1em;
        margin-bottom: 1rem;
        margin-top:0;
        font-weight: 400;
        margin-right: 2rem;
        @include breakpoint(medium){
            font-size: 2.3rem;
           margin-bottom: 0;
        }
        @include breakpoint(large){
            font-size: 2.7rem;

        }
    }
    &__text{
        color: $white;
        font-size: 1.2rem;
        font-family:$font-2;
        margin-top:0rem;
        @include breakpoint(medium){
             margin-top:1rem;
        }
       
    }
    &__text--bok{
        color: $white;
        font-size: 1.3rem;
        font-family:$font-2;
        
        @include breakpoint(medium){
            margin-top:2rem;
            
        }
       
    }
    &__text--podpis{
        color: $white;
        font-size: 0.6rem;
        font-family:$font-1;
        margin-top:0.1rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        line-height:0.5em;
        margin-bottom:10px;
        @include breakpoint(medium){
          font-size: 0.5rem;
        }
    }
    &__foto--bottom{  
     align-self:flex-end;
     width: 100%;
     margin-bottom:-6rem; //jak padding
     margin-top:1.5rem;
    }
    &__list{
      margin-top: 1rem;
      color:white;
       margin-left:1rem;
      @include breakpoint(medium){
         margin-left:2rem;  
      }
      li{
        color:white;
        margin-bottom:0.9rem;
        font-size:0.92rem;
      }
    }

    &--white{
      background: #ffffff; /* Old browsers */
      background: -moz-linear-gradient(top, #ffffff 70%, #eee 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #ffffff 70%,#eee 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #ffffff 70%,#eee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      .cecha__header{
        color:$black;
      }
      .cecha__logo{
        // width:auto;
        path{
          fill:$black;
        } 
      }
      .cecha__text, .cecha__text--podpis{
        color:$black;
      }   
      .cecha__list{

        li{
          color:$black;
          }        
      }  
    }
     &--dopracowany{
      background: #0a0a0a;
     }


}

.text--white{
  color:#fff;
}

.naglowek-kapit{
    color: $white;
    font-family: "nimbus-sans-condensed",sans-serif;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
   
   
}

//
//     ###  ######  ## ##    #    ####  ######  #####          ###   ##     ####   ####   #####  ####
//    ## ##   ##    ## ##   ###   ## ##   ##    ##            ## ##  ##      ##    ## ##  ##     ## ##
//    ## ##   ##    #####  ## ##  ## ##   ##    ##            ##     ##      ##    ## ##  ##     ## ##
//    ## ##   ##    #####  ## ##  ####    ##    ####           ###   ##      ##    ## ##  ####   ####
//    ## ##   ##    #####  #####  ###     ##    ##               ##  ##      ##    ## ##  ##     ###
//    ## ##   ##    ## ##  ## ##  ####    ##    ##            ## ##  ##      ##    ## ##  ##     ####
//     ###    ##    #   #  ## ##  ## ##   ##    #####          ###   #####  ####   ####   #####  ## ##
//
//
.otwarty-slider{
  margin-top:1rem;
}
.otwarty-slider__wrapper {
    
    position: relative;
    

    @include breakpoint(970px) {
        // justify-content: space-around;
    }
}

.otwarty-slider__item {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all ease 300ms;
    padding: 2rem 2rem;
    transform-origin: center center;
    @include breakpoint(640px) {
        
    }


    @include breakpoint(970px) {
       
       
    }
}



.otwarty-slider__item img{
  width: 80%;
  max-width:140px;
  @include breakpoint(medium){
     width: 80%;
       max-width:auto;
  }
  }
.otwarty-slider__item.is-active{
 transform: scale(1.4);   
}

.otwarty-slider__item.is-active{
   
}

//
//      ## ##    #    #####  ##     #####
//      ## ##   ###   ##     ##     ##
//      ####   ## ##  ##     ##     ##
//      ###    ## ##  ####   ##     ####
//      ####   #####  ##     ##     ##
//      ## ##  ## ##  ##     ##     ##
//      ## ##  ## ##  ##     #####  #####
//
//



.kafel{
    background: $black-lighter;
    display: flex;
    
    flex-wrap:wrap;
    height:100%;
    @include breakpoint(900px){
        flex-wrap:nowrap;
    }
    &__image{
        width: 100%;
        height:40vh;
        max-height:280px;
        position:relative;
        @include breakpoint(medium){
            height:25vh;
            max-height:320px;
        }
        @include breakpoint(900px){
            width: 40%;  
            height:auto;
            max-height:inherit; 
        }
        img{
          position:absolute;
          top:0;
          object-fit:cover;
          height: 100%;
        width: 100%;
      }
    }

    &__body{
       
       padding:1rem 0.9rem 0.6rem 1.1rem;
       @include breakpoint(900px){
          width: 60%;
             padding:0.6rem 0.9rem 0.6rem 1.1rem;
       }
       img{
        width: 30px;
        margin-bottom: 10px;
       
       }
       h3{
        color: $white;
        font-family: $font-2;
        font-size: 26px;
        line-height: 1.3em;
        margin-top:0;
       }
       p{
        color: $white;
        font-size: 16px;
        margin-top:0;
        margin-bottom:1rem;

       }
    }
}

.kafel-tlo{
    position:relative;
    overflow: hidden;
    padding:1rem 2rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items:center;
    height: 40vh;
    min-height: 280px;
     
    &:hover{
        .kafel-tlo__image{
             min-width: 110%;
            min-height: 110%;
            opacity: 0.2;
            
        }
    }
     &__image{
        transform: translateZ(0);
        
        opacity: 0.4;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        transition: all 0.8s ease;
     }
      &__body{
        // position:absolute;
        // top:50%;
        // left:50%;
        // transform: translate(-50%, -50%);
        
        z-index: 1;
      }
     h3{
        color: $white;
        font-family: $font-3;
        font-size: 1.5rem;
        line-height: 1rem;
        letter-spacing: 0.2em;
        margin-bottom:0.8rem;
        small{
            font-family: $font-2;
            font-size: 12px;
          
            margin-bottom:0.2rem;
            display: block;
        }
       }
      p{
        color: $white;
        font-size: 20px;
        line-height: 1.4em;
        font-family: $font-2;
         max-width:350px;
       }
}


.realizacje-glowna{
    &__item{
          display: block;
          cursor:pointer;
          position:relative;
          margin:2.5%;
          @include breakpoint(large){
              margin:1%;
          }
          div{
              overflow:hidden;
          }
          img{
              width:100%;
              height:200px; 
              object-fit:cover;
              transition: transform 0.6s ease, opacity 0.2s ease;
              @include breakpoint(medium){
                 
              }
          }
          figcaption{
              transition: all 0.4s ease;
              color: #fff;
              font-size: 0.9rem;
              display: block;
              font-weight: 400;
              margin-top:0.3rem;
              line-height:1em; 
              width: 100%;
              text-shadow: 0 0 30px #000;
              margin-bottom:1.2rem;
              @include breakpoint(large){
                  font-size: 0.8rem;
                  position:absolute;
                  top:50%;
                  left:50%;
                  transform:translate(-50%,-50%) scale(0.8);
                  opacity:0;
                  text-align:center;
              }
              small{
                  color:#fff;
                  padding:0.4rem 0.4rem 0.4rem 0;
                  display: block;
                  font-size: 0.6rem;
                  letter-spacing: 0.15em;
                  line-height:0.8em;
                  @include breakpoint(large){
                    text-align:center;
                    font-size: 0.5rem;
                  }
              }
          }
          &:hover{
              img{
                   @include breakpoint(large){
                      transform: scale(1.1);
                      opacity:0.8;
                   }
               }
               figcaption{
                   @include breakpoint(large){
                        opacity:1;
                        transform:translate(-50%,-50%) scale(1);
                   }
               }
          }
      }
  }


//
//       ###   ##     ####   ####   #####  ####
//      ## ##  ##      ##    ## ##  ##     ## ##
//      ##     ##      ##    ## ##  ##     ## ##
//       ###   ##      ##    ## ##  ####   ####
//         ##  ##      ##    ## ##  ##     ###
//      ## ##  ##      ##    ## ##  ##     ####
//       ###   #####  ####   ####   #####  ## ##
//
//

.klienci-slider{

      .swiper-wrapper{
        display: flex;
        align-items: center;
      }  

     .swiper-pagination{
        margin-top:30px;
        position:relative;
     }
     
      img{
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%);

      }
    .swiper-pagination-bullet-active{
        background-color: $white;
    }


}

//
//      #  ##    #     ###   ####    ###   ####   ## ##
//      ## ##   ###   ## ##  ## ##  ## ##  ## ##  ## ##
//      #####  ## ##  ##     ## ##  ## ##  ## ##  ## ##
//      #####  ## ##  #####  ####   ## ##  ## ##   ###
//      #####  #####  ## ##  ###    ## ##  ## ##   ###
//      ## ##  ## ##  ## ##  ####   ## ##  ## ##   ###
//      ##  #  ## ##   ####  ## ##   ###   ####    ###
//
//

.nagroda{
    background: #000;
    display: flex;
    margin-bottom: 30px;

    &__image{
        width: 40%;
    }

    &__body{
       width: 60%;
       padding:20px 15px 20px 30px;
       img{
        position:absolute;
        bottom:50px;
        height: 20px;
       }
       h3{
        color: $white;
        font-family: $font-1;
        font-size: 17px;
        line-height: 1.3em;
        margin-bottom:20px;
        width: 70%;
        
        float: left;
       }
        h4{
            float: right;
            width: 30%;
            
            color: $white;
            font-family: $font-1;
            font-size: 11px;
            letter-spacing: 0.2em;
            text-align:right;
            line-height: 1.3em;
            margin-bottom:20px;

        }
       p{
        color: $white;
        font-size: 22px;
        font-family: $font-2;
        span{
            font-size: 24px;
            font-family: $font-2;
            margin-left: -10px;

        }
       
       }
    }
}

//
//     ###   #####  ## ## ######  ## ##
//    ## ##     ##  ## ##   ##    ## ##
//    ##       ###  ## ##   ##    ## ##
//     ###    ###    ###    ##     ###
//       ##  ###     ###    ##     ###
//    ## ##  ##      ###    ##     ###
//     ###   #####   ###    ##     ###
//
//

.le-corbusier{
  position:absolute;
  &__twarz{
    max-width:400px;
    width:60%;
    min-width:200px;
    float:left;
  }
}

//
//      #####   ###   ####   ####   ## ##  ####     #    #####  ## ##    ###  #####
//         ##  ## ##  ## ##   ##    ## ##   ##     ###      ##  ## ##     ##  ##
//        ###  ## ##  ## ##   ##    #####   ##    ## ##    ###  ## ##     ##  ##
//       ###   ## ##  ####    ##    #####   ##    ## ##   ###   ## ##     ##  ####
//      ###    ## ##  ## ##   ##    #####   ##    #####  ###    ## ##  ## ##  ##
//      ##     ## ##  ## ##   ##    ## ##   ##    ## ##  ##     ## ##  ## ##  ##
//      #####   ###   ####   ####   #   #  ####   ## ##  #####   ###    ###   #####
//
//



.foto-full{
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items:center; 
    justify-content: center;
    h2{
        color: $white;
        margin-top:0;
        font-size:44px;
        letter-spacing: 0.2em;
        font-family:"nimbus-sans-condensed";
    }
    p{
      max-width:500px;
      text-align: center;
    }
    &__big-text{
        color: $white;
        font-size:33px;
        font-family: $font-2;
        @include breakpoint(medium){
            font-size:52px;
        }
    }
    &--full{
        height: 100vh;
    }
}
.parallax-slider{
    opacity: 0.5;
    display: none;
    @include breakpoint(medium){
        display: block;
    }
}




//hack dla ios - nie obsluguje vh
.ios .wydarzenia {
    bottom: 30px;
    @include breakpoint(medium){
        bottom: 30px;
    }
}


.svg.strzalka{
  fill:#fff;
  width:1rem;
}



