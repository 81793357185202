//
//       ###   ##     ####   ####   #####  ####
//      ## ##  ##      ##    ## ##  ##     ## ##
//      ##     ##      ##    ## ##  ##     ## ##
//       ###   ##      ##    ## ##  ####   ####
//         ##  ##      ##    ## ##  ##     ###
//      ## ##  ##      ##    ## ##  ##     ####
//       ###   #####  ####   ####   #####  ## ##
//
//


// @include breakpoint(medium){
//     max-height:initial;
// }
// @include breakpoint(large){
//    height: 100vh; 
//    padding-bottom:2rem;  
// }



.slider {
    // opacity: 1;
    // width: 100%;
    // min-height: 350px;
    // height:100vh;
    // overflow: hidden;
    // position:relative;  
    position:relative;
display: flex;
justify-content: center;
align-items:flex-end;
overflow: hidden;
height:100%;
width:100%;
position: absolute;
flex-wrap: wrap;
background:#000; 
opacity: 0.85;
    &__header{
        position:absolute;
        bottom:2vh;
        left:1rem;
        z-index:4;
        background:#fff;
        width:30rem;
        padding:0.7rem 1.1rem;
        font-size:1.1rem;
        margin-left:0.6rem;
        @include breakpoint (medium down){
            width:auto;
            margin:0 0;
            right:1rem;
        }
    }
    &__item {
       
        // text-align: center;  
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position:realtive;
        img{
            width: 100%;
            height:100%;
            position:absolute;
            top:0;
            object-fit: cover;
           
        }
    }

    &__link{
        background:$black;
        color: #ddd;
        padding:8px 12px;
        transition: all 100ms ease-in-out;
        &:hover{
            color: #fff;
        }
    }
    &__arrow {
        display: none;
        padding:0.3rem 0.3rem;
        background:#fff;
        border:0.5rem solid #fff;
        background-repeat: no-repeat;
        background-position: center;
        @include breakpoint(medium){
           

        }
        &--right {
            right: 1rem;
          
        }
        &--left {
            left: 1rem;
           
        }
    }
}

.ios .slider .slider__item {
    height: 400px;

     @include breakpoint(medium){
        height: 600px;
    }
}





