// 	                                          
// 	 #####   ###    ###  ######  #####  ####  
// 	 ##     ## ##  ## ##   ##    ##     ## ## 
// 	 ##     ## ##  ## ##   ##    ##     ## ## 
// 	 ####   ## ##  ## ##   ##    ####   ####  
// 	 ##     ## ##  ## ##   ##    ##     ###   
// 	 ##     ## ##  ## ##   ##    ##     ####  
// 	 ##      ###    ###    ##    #####  ## ## 
// 	                                          
// 	     




.footer{
	background: $secondary-color;
    margin-top: 50px;
    padding-top:40px;
    padding-bottom:40px;
    font-family: $font-2;
    &__sygnet{
        width: 95px;
        margin-bottom: 15px;
         margin-right: -5px;
         
        @include breakpoint(medium){
          
            margin-right: -10px;
        }
        path{
            fill:white;
        }
    }
    &__name{
        color: $white;
        font-size: 15px;
        line-height: 1.75em;
        margin-bottom: 20px;
       
        
    }
    span{
        color: $white;
        font-size: 15px;
        line-height: 1.5em;
        font-style: normal;
        margin-top: 30px;
    }
     a{
            color: $white;
            font-size: 15px;
            line-height: 1.5em;
            transition: all ease 200ms;
        }
        a:hover{
            color: $white;
            
        }

    &__kontakt{
         
         text-align: left; 
         margin-top:20px;
         @include breakpoint(medium){
            margin-top:25px;
            text-align: right; 
            float: right;
         }
         @include breakpoint(large){
            margin-top:55px;
         }

       
       
    }

    &__nav{
        list-style: none;
        padding: 0px;
       
       margin-top: 0;
        display: none;
        float: right; 
        
        @include breakpoint(medium){
            display: block; 
           
        }
        
       li{
       
        margin-left: 30px;
        text-align: right;
        margin-bottom: 10px;

        @include breakpoint(large){
            float: left;
             margin-bottom: 0;
         }  
        
            a{
                text-align: right;
                transition: all ease 200ms;
              
                text-decoration: none;
                color: #fff;
                letter-spacing: .5px;
              
                font-size: 0.8rem;
                letter-spacing: 0.05em;
        
                &:hover{
                    
                }
            }    
        }

        .is--active a{
            box-shadow: 3px 3px 0 #000;
            background: white;
        }
    }
}