
.galeria{
   
    
        &__header{
            font-size: 36px;
        }
        &__filter-group{
            margin-bottom: 30px;
            .is-checked{
                font-weight: bold;
            } 
        }

        &__filter-item{
            margin-right: 20px;
        }

        &__sizer{
                // width:25%;
               @include flex-grid-column(12);

               @include breakpoint(medium){
                    @include flex-grid-column(4);
               }
               @include breakpoint(large){
                    @include flex-grid-column(3);
               }
        }
        &__item{
            // transition: all 200ms ease-in;
           margin-bottom: 15px;
           
            figure{
                margin: 0;
            }
            figcaption{
                font-size: 12px;
                margin-bottom: 8px;
                opacity: 0;
                transition: all 300ms ease-in;
            }
           &:hover{
                opacity: 0.8;
                figcaption{
                    opacity: 1;
                    margin: 0;
                }
            }

        }
        
}


figcaption{
                font-size: 12px;
               
                transition: all 300ms ease-in;
            }
.filter{
    margin-right: 20px;
    
}
.header--large2{
    font-size: 92px;
    letter-spacing: 6px;

}

.filter.active{
    font-weight: bold;
}

#Container .mix{
    display: none;
}

.chocolat-overlay {
    opacity: 0.94;
}