.grid-container{

	max-width: 1200px;
}



img.image-fit{
    object-fit: none; 
    object-position: 50% 0;
    @include breakpoint(1050px down){
    	object-fit: scale-down; 
    }
}

img.image-fit--left{
   
    object-position: 0 0;
}

