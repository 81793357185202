//
//       ###    ###   #  ## ######  #####  #  ## ######
//      ## ##  ## ##  ## ##   ##    ##     ## ##   ##
//      ##     ## ##  #####   ##    ##     #####   ##
//      ##     ## ##  #####   ##    ####   #####   ##
//      ##     ## ##  #####   ##    ##     #####   ##
//      ## ##  ## ##  ## ##   ##    ##     ## ##   ##
//       ###    ###   ##  #   ##    #####  ##  #   ##
//
//

p{
 
}
h1,h2,h3,h4{
    font-family: $font-2;
    margin-bottom:1rem;
} 



table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom:1.5rem;
}
table caption{
	margin-bottom:0.7rem;
	letter-spacing: 0.1rem;
}
table * {
  position: relative;
}
table td, table th {
  padding: 0.5rem 0.3rem;
  vertical-align: top;
  border:1px solid $gray-dark;
}
table thead tr {
  height: 2.5rem;
  background: #36304a;
}
table tbody tr {
  height: 2rem;
}
table tbody tr:last-child {
  border: 0;
}
table td, table th {
  text-align: left;
}
table td.l, table th.l {
  text-align: right;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr {
  font-size: 0.8rem;
  color: #555;
  line-height: 1.2;
  font-weight: unset;
}




@media screen and (max-width: 992px) {
  table {
    display: block;
  }
  table > *, table tr, table td, table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 1.5rem 0;
  }
  table tbody tr td {
    padding-left: 40% !important;
    margin-bottom: 1rem;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  table tbody tr td:before {
   
    font-size: 0.8rem;
    color: #999999;
    line-height: 1.2;
    font-weight: unset;
    position: absolute;
    width: 40%;
    left: 30px;
    top: 0;
  }

  tbody tr {
    font-size: 0.8rem;
  }
}



